import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { LOGIN } from 'lib/routes';
import { useAuth } from 'hooks/auth';
import Navbar from 'components/layout/Navbar';
import Sidebar from './Sidebar';
import { Box, Flex, Spinner } from '@chakra-ui/react';

/*
  Only applies to authenticated users
*/
export default function Layout(props) {
  let pathname = useLocation().pathname;
  let navigate = useNavigate();
  let { user: authUser, isLoading: isAuthLoading } = useAuth();

  useEffect(() => {
    // Secure paths under /app
    if (pathname.startsWith('/app') && !isAuthLoading && !authUser) {
      navigate(LOGIN);
    }
  }, [pathname, authUser, navigate, isAuthLoading]);

  if (isAuthLoading || !authUser) return <Spinner />;

  let isAdmin = authUser.isAdmin;

  return (
    <>
      <Navbar isAdmin={isAdmin} />

      <Flex
        pt="16"
        pb="12"
        mx="auto"
        w="full"
        maxW="1200px"
        textStyle="nearBody"
        bg="nearBackground"
      >
        <Box w="900px">
          <Outlet />
        </Box>
        <Sidebar />
      </Flex>
    </>
  );
}
