import { uuidv4 } from '@firebase/util';
import {
  collection,
  deleteDoc,
  doc,
  endAt,
  getDocs,
  orderBy,
  query,
  setDoc,
  startAt,
  where,
  writeBatch
} from 'firebase/firestore';
import { db } from 'lib/firebase';
import { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { toaster } from '../components/ui/toaster';

export function cleanTagText(text) {
  if (!text) return text;

  // Remove #, trim and lowercase
  let outText = text.replace('#', '').trim().toLowerCase();

  // Replace whitespace with dashes
  outText = outText.replace(/\W/g, '-');

  // Remove any remaining characters besides alphanums and dashes
  outText = outText.replace(/[^a-z0-9-]/g, '');

  // Replace non-alphanums (dashes) at the beginning and end
  outText = outText.replace(/[^a-z0-9]*/, '').replace(/[^a-z0-9]*$/, '');

  // Replace repeating dashes with a single dash
  outText = outText.replace(/-+/g, '-');

  return outText;
}

export function useAddTag() {
  const [isLoading, setLoading] = useState(false);

  async function addTag(tag) {
    setLoading(true);
    const id = uuidv4();
    const docRef = doc(db, 'tags', id);

    // Clean the tag and make sure we have at least 3 alpha characters
    let tagText = cleanTagText(tag);
    let tagAlphas = tagText.replace(/[^a-z0-9]/g, '');
    if (tagText && tagAlphas.length > 2) {
      await setDoc(docRef, {
        text: tagText,
        id,
        createDate: Date.now()
      });

      toaster.create({
        title: 'Tag added',
        type: 'success',
        duration: 5000
      });
    } else {
      toaster.create({
        title:
          'Error creating tag, must have at least 3 alphanumeric characters',
        type: 'error',
        duration: 5000
      });
    }
    setLoading(false);
  }

  return { addTag, isLoading };
}

export function useTags({ text, ids }) {
  let constraints = [];
  let clauses = [];

  if (ids) {
    clauses.push(where('id', 'in', ids));
  }

  let tagText = cleanTagText(text);
  if (tagText) {
    constraints = [startAt(tagText), endAt(tagText + '\uf8ff')];
  }
  const q = query(
    collection(db, 'tags'),
    ...clauses,
    orderBy('text', 'asc'),
    ...constraints
  );
  const [tags, isLoading, error] = useCollectionData(q);
  if (error) throw error;

  return { tags, isLoading };
}

export function useDeleteTag() {
  const [isLoading, setLoading] = useState(false);

  async function deleteTag(id) {
    // First, get the full list of post tags based on this tag
    let q = query(collection(db, 'post_tags'), where('tagId', '==', id));
    let postTagsQuerySnapshot = await getDocs(q);
    let postTagsCount = postTagsQuerySnapshot.docs.length;

    const res =
      postTagsCount > 0
        ? window.confirm(
            `There are ${postTagsCount} posts with this tag. Are you sure you want to delete it?`
          )
        : window.confirm(
            `Are you sure you want to delete this tag? There are no associated posts, but still.`
          );
    if (res) {
      setLoading(true);

      // Delete all post tags with this tag
      let batch = writeBatch(db);
      postTagsQuerySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      const docRef = doc(db, 'tags', id);
      await deleteDoc(docRef);

      toaster.create({
        title: 'Tag deleted',
        type: 'success',
        duration: 5000
      });

      setLoading(false);
    }
  }
  return { deleteTag, isLoading };
}
