import { Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';

import Avatar from 'components/profile/Avatar';
import { useUser } from 'hooks/users';
import UsernameButton from 'components/profile/UsernameButton';

export default function Header({ post }) {
  const { uid, createDate: date, title, description } = post;
  const { user, isLoading } = useUser(uid);

  if (isLoading || !user) return <Spinner />;

  return (
    <Flex
      alignItems="center"
      borderBottom="2px solid"
      borderColor="themeColor"
      p="3"
      bg="containerHeaderBackground"
    >
      <VStack>
        <Avatar user={user} size="md" />
        <UsernameButton user={user} />
      </VStack>

      <VStack align="start">
        <Text wordBreak="break-word" fontSize="lg" fontWeight="600">
          {title}
        </Text>
        <Text wordBreak="break-word" fontSize="md">
          {description}
        </Text>
        <Text fontSize="sm">{formatDistanceToNow(date)} ago</Text>
      </VStack>
    </Flex>
  );
}
