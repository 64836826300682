import { useUploads } from 'hooks/uploads';

import {
  Box,
  Button,
  VStack,
  FileUpload as ChakraFileUpload
} from '@chakra-ui/react';

import {
  FileUploadClearTrigger,
  FileUploadDropzone,
  FileInput,
  FileUploadRoot
  //FileUploadTrigger
} from '../../components/ui/file-upload';

import { CloseButton } from '../../components/ui/close-button';
import { InputGroup } from '../../components/ui/input-group';

import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useAddVideo } from 'hooks/posts';

import { FaUpload } from 'react-icons/fa6';

function validateVideoFile(value) {
  if (value.length < 1) {
    return 'File is required';
  } else if (value.length > 1) {
    return 'Only one file can be included on a post';
  }

  for (const file of Array.from(value)) {
    const fsMb = file.size / (1024 * 1024);
    const MAX_FILE_SIZE = 300;
    if (fsMb > MAX_FILE_SIZE) {
      return 'Max file size 300mb';
    }
  }
  return true;
}

export default function VideoFileUpload(props) {
  const { register, handleSubmit, reset } = useForm();
  const { addVideo, isLoading: addingVideo } = useAddVideo();
  const inputRef = useRef(null);
  const handleClick = () => inputRef.current?.click();

  const { authuser: authUser, authtoken: authToken } = props;

  // Set up upload handler
  let onVideoUploaded = (videoId) => {
    console.log(videoId);
  };
  if (props.onVideoUploaded) {
    onVideoUploaded = props.onVideoUploaded;
  }

  let inputProps = { ...props };
  delete inputProps.onVideoUploaded;

  const {
    uploadUrl,
    uploadToken,
    isLoading: uploadLoading,
    error: uploadError
  } = useUploads(authToken);

  function handleUploadVideo(data) {
    async function upload(data) {
      let videoId = await addVideo({
        uid: authUser.id,
        ...data
      });

      reset();

      return onVideoUploaded(videoId);
    }

    upload(data);
  }

  if (uploadError) {
    return 'Error rendering upload component';
  } else {
    return (
      <Box maxW="800px" py="10" alignItems="left">
        <form onSubmit={handleSubmit(handleUploadVideo)}>
          <VStack align="left">
            <FileUploadRoot
              onClick={handleClick}
              accept={['video/*']}
              {...inputProps}
              {...register('file', {
                required: true,
                validate: validateVideoFile
              })}
            >
              {!uploadLoading ? (
                <>
                  <ChakraFileUpload.HiddenInput
                    type="hidden"
                    id="uploadUrl"
                    name="uploadUrl"
                    value={uploadUrl || ''}
                    {...register('uploadUrl', { required: true })}
                  />
                  <ChakraFileUpload.HiddenInput
                    type="hidden"
                    id="uploadToken"
                    name="uploadToken"
                    value={uploadToken || ''}
                    {...register('uploadToken', { required: true })}
                  />
                </>
              ) : (
                ''
              )}

              <FileUploadDropzone
                w="full"
                label="Drag and drop here to upload"
                description="Video files up to 300MB"
              />

              {/*
              <FileUploadTrigger asChild>
                <Button variant="outline" size="sm">
                  <FaUpload /> Upload file
                </Button>
              </FileUploadTrigger>
              */}

              <InputGroup
                w="full"
                startElement={<FaUpload />}
                endElement={
                  <FileUploadClearTrigger asChild>
                    <CloseButton
                      me="-1"
                      size="xs"
                      variant="plain"
                      focusVisibleRing="inside"
                      focusRingWidth="2px"
                      pointerEvents="auto"
                      color="fg.subtle"
                    />
                  </FileUploadClearTrigger>
                }
              >
                <FileInput />
              </InputGroup>
            </FileUploadRoot>
            <Button
              colorPalette="themeColor"
              maxWidth="300px"
              type="submit"
              isLoading={uploadLoading || addingVideo}
              loadingText="Loading..."
            >
              Upload
            </Button>
          </VStack>
        </form>
      </Box>
    );
  }
}
