import {
  createListCollection,
  Box,
  Button,
  HStack,
  Input,
  Spinner,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText
} from '../../components/ui/select';

import TagSelect from '../tags/TagSelect';

import {
  useAddPostTag,
  usePost,
  usePostTags,
  useRemovePostTag,
  useUpdatePost
} from 'hooks/posts';
import { ConnectionType } from 'lib/enums';
import { Navigate, useParams } from 'react-router-dom';
import { useTags } from 'hooks/tags';
import { useAuth } from 'hooks/auth';
import { useState } from 'react';

export default function EditPost(params) {
  const { id } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const { updatePost, isLoading: updatePostLoading } = useUpdatePost();
  const { post, isLoading: postLoading } = usePost(id);
  const { user: authUser, isLoading: authUserLoading } = useAuth();
  const [visibility, setVisibility] = useState([]);

  const { postTags, isLoading: postTagsLoading } = usePostTags(id);
  const { tags, isLoading: tagsLoading } = useTags({});

  const { addTag } = useAddPostTag(authUser ? authUser.id : '');
  const { removeTag, isLoading: removePostTagLoading } = useRemovePostTag();

  function handleUpdatePost(data) {
    async function update() {
      await updatePost({
        id,
        description: data.description,
        title: data.title,
        visibility: visibility ? visibility[0] : null
      });
      reset();
      setSubmitted(true);
    }

    update();
  }

  // Putting in this logic so that even when we don't change the visibility selection, it retains it's value
  if (post && post.visibility && visibility.length === 0) {
    setVisibility([post.visibility]);
  }

  // Get a filtered list of tags not already selected by the user, to use in the selection list
  let availableTags = [];
  if (!postTagsLoading && !tagsLoading) {
    let postTagIds = postTags.map((postTag) => postTag.tagId);
    for (let tag of tags) {
      if (!postTagIds.includes(tag.id)) {
        availableTags.push(tag);
      }
    }
  }

  function handleAddTag(tag) {
    let tagObj = JSON.parse(tag);
    addTag(post, tagObj);
  }

  const audiences = createListCollection({
    items: Object.entries(ConnectionType).map((kv) => {
      return { label: kv[0], value: kv[1] };
    })
  });

  if (authUserLoading || postLoading) return '';
  return (
    <Box maxW="600px" mx="auto" py="5">
      <form onSubmit={handleSubmit(handleUpdatePost)}>
        <Stack spacing={3}>
          <Input
            size="lg"
            {...register('title', { required: true, value: post.title })}
            placeholder="Title"
          />

          <Textarea
            as={TextareaAutosize}
            resize="none"
            mt="5"
            placeholder={'Description'}
            minRows={2}
            {...register('description', {
              required: true,
              value: post.description
            })}
          />

          <HStack>
            <Text size="lg">Audience</Text>
            <SelectRoot
              collection={audiences}
              onValueChange={(e) => {
                setVisibility(e.value);
              }}
              value={visibility}
            >
              <SelectTrigger>
                <SelectValueText placeholder="Select an audience" />
              </SelectTrigger>
              <SelectContent>
                {audiences.items.map((audience) => (
                  <SelectItem item={audience} key={audience.value}>
                    {audience.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </HStack>

          <TagSelect
            preSelected={
              postTags
                ? postTags.map((t) => {
                    return t.tagId;
                  })
                : []
            }
          ></TagSelect>

          <Button
            colorPalette="themeColor"
            type="submit"
            isLoading={updatePostLoading}
            loadingText="Loading..."
          >
            Save
          </Button>
        </Stack>
      </form>

      {submitted ? <Navigate to={`/app/dashboard`} replace={true} /> : ''}
    </Box>
  );
}
