import { Box, Spinner } from '@chakra-ui/react';

import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot
} from '../../components/ui/accordion';

import { useInterests } from 'hooks/interests';

// Render either a accordion item or an additional accordion root with InterestTreeBranch's inside
function InterestTreeBranch(params) {
  const { interest, indent } = params;

  if (interest['children'] && interest['children'].length > 0) {
    return (
      <AccordionItem key={interest['id']} value={interest['id']}>
        <AccordionItemTrigger>
          {interest['emoji']} {interest['displayText']}
        </AccordionItemTrigger>
        <AccordionItemContent>
          <Box ml={2 * indent}>
            <AccordionRoot multiple collapsible>
              {interest['children'].map((child, index) => (
                <InterestTreeBranch
                  key={index}
                  interest={child}
                  indent={2 * indent}
                />
              ))}
            </AccordionRoot>
          </Box>
        </AccordionItemContent>
      </AccordionItem>
    );
  } else {
    return (
      <AccordionItem key={interest['id']} value={interest['id']}>
        {interest['emoji']} {interest['displayText']}
      </AccordionItem>
    );
  }
}

export default function InterestTree(params) {
  const { text } = params;
  const { interests, isLoading } = useInterests({
    text,
    hierarchical: true,
    selectableOnly: true
  });

  if (!interests || isLoading) return <Spinner />;

  return (
    <Box maxWidth="800px" ml={2}>
      <AccordionRoot multiple collapsible>
        {interests.map((interest, index) => (
          <InterestTreeBranch key={index} interest={interest} indent={2} />
        ))}
      </AccordionRoot>
    </Box>
  );
}
