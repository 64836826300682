import { Box, Button, Flex, Input, Spinner } from '@chakra-ui/react';
import { useAuth } from 'hooks/auth';
import { useAddTag } from 'hooks/tags';
import { useForm } from 'react-hook-form';

export default function NewTag() {
  const { user, isLoading: userLoading } = useAuth();
  const { register, handleSubmit, reset } = useForm();
  const { addTag, isLoading: addTagLoading } = useAddTag();

  if (!user || userLoading || addTagLoading) return <Spinner />;

  function handleAddTag(data) {
    addTag(data.text);
    reset();
  }

  return (
    <Box maxW="800px" mx="auto" py="6">
      <Flex padding="4">
        <Box flex="1" ml="4">
          <form onSubmit={handleSubmit(handleAddTag)}>
            <Box>
              <Input
                size="sm"
                variant="flushed"
                placeholder="Create a new tag..."
                autoComplete="off"
                {...register('text', { required: true })}
              />
            </Box>
            <Flex pt="2">
              <Button
                type="submit"
                colorPalette="themeColor"
                size="xs"
                ml="auto"
                isLoading={addTagLoading || userLoading}
              >
                Add Tag
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}
