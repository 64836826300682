import { Flex, HStack, IconButton, Spinner } from '@chakra-ui/react';

import { FaBinoculars, FaDoorClosed, FaDoorOpen } from 'react-icons/fa6';
import { useDisableUser } from 'hooks/users';
import { ConnectionType } from 'lib/enums';
import { useAddConnection, useDeleteConnection } from 'hooks/connections';

import { Tooltip } from '../../components/ui/tooltip';

export default function Actions({ user, authUser, isFollowed }) {
  const { id, isDisabled } = user;

  const { updateDisabled, isLoading: disableUserLoading } = useDisableUser(
    id,
    !isDisabled
  );
  const { addConnection: follow, isLoading: followLoading } = useAddConnection(
    authUser,
    user,
    ConnectionType.Follow
  );
  const { deleteConnection: unfollow, isLoading: unfollowLoading } =
    useDeleteConnection(authUser, user, ConnectionType.Follow);

  if (disableUserLoading || followLoading || unfollowLoading)
    return <Spinner />;

  return (
    <HStack>
      <Flex borderBottom="1px solid" borderColor="themeColor" pb="2">
        {user.id !== authUser.id ? (
          isFollowed ? (
            <Tooltip content="Unfollow">
              <IconButton
                size="md"
                ml="auto"
                colorPalette="red"
                isRound
                onClick={unfollow}
                isLoading={unfollowLoading}
              >
                <FaBinoculars />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip content="Follow">
              <IconButton
                size="md"
                ml="auto"
                colorPalette="themeColor"
                variant="ghost"
                isRound
                onClick={follow}
                isLoading={followLoading}
              >
                <FaBinoculars />
              </IconButton>
            </Tooltip>
          )
        ) : (
          ''
        )}
      </Flex>

      <Flex borderBottom="1px solid" borderColor="themeColor" pb="2">
        {user.id !== authUser.id ? (
          authUser.isAdmin || authUser.isHosting ? (
            !isDisabled ? (
              <Tooltip content="Disable user">
                <IconButton
                  size="md"
                  ml="auto"
                  colorPalette="red"
                  variant="ghost"
                  isRound
                  onClick={updateDisabled}
                  isLoading={disableUserLoading}
                >
                  <FaDoorClosed />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip content="Enable user">
                <IconButton
                  size="md"
                  ml="auto"
                  colorPalette="themeColor"
                  isRound
                  onClick={updateDisabled}
                  isLoading={disableUserLoading}
                >
                  <FaDoorOpen />
                </IconButton>
              </Tooltip>
            )
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </Flex>
    </HStack>
  );
}
