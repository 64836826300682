import { Box, Button, Code, Spinner, Stack } from '@chakra-ui/react';
import Avatar from 'components/profile/Avatar';
import { useAuth } from 'hooks/auth';
import { APP_USERS } from 'lib/routes';
import { Link } from 'react-router-dom';

function ActiveUser() {
  const { user, isLoading } = useAuth();

  if (isLoading) return <Spinner />;

  return (
    <Stack align="center" spacing="5" my="8">
      <Avatar user={user} />
      <Code>@{user.username}</Code>
    </Stack>
  );
}

export default function Sidebar(props) {
  return (
    <Box
      px="6"
      height="100vh"
      w="100%"
      maxW="300px"
      borderLeft="1px solid"
      borderLeftColor="themeColor"
      position="sticky"
      top="16"
      display={{ base: 'none', md: 'block' }}
    >
      <ActiveUser />
      <Box align="center">
        <Box as="ul" borderBottom="2px solid" borderColor="themeColor" />
        <Button
          variant="outline"
          colorPalette="themeColor"
          as={Link}
          to={APP_USERS}
          mt="4"
          size="sm"
        >
          ALL USERS
        </Button>
      </Box>
    </Box>
  );
}
