import { Button, Code, Spinner, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { APP } from 'lib/routes';
import Avatar from 'components/profile/Avatar';
import Actions from './Actions';
import { useUser } from 'hooks/users';

export default function User({
  user,
  uid = null,
  authUser,
  isFollowed,
  includeActions = true
}) {
  const { user: lookupUser, isLoading: userLoading } = useUser(uid);

  if (userLoading) return <Spinner />;

  const renderUser = lookupUser || user;
  const { id, username } = renderUser;

  let actions = '';
  if (includeActions) {
    actions = (
      <Actions user={renderUser} authUser={authUser} isFollowed={isFollowed} />
    );
  }

  return (
    <VStack
      bg="containerBackground"
      rounded="md"
      textAlign="center"
      p="4"
      spacing="3"
      textStyle="nearBody"
      border="2px solid"
      borderRadius="sm"
      borderColor="containerBorder"
    >
      <Avatar user={renderUser} />
      <Code>@{username}</Code>
      <Button
        as={Link}
        to={`${APP}/profile/${id}`}
        size="sm"
        variant="link"
        colorPalette="purple"
      >
        View Profile
      </Button>
      {actions}
    </VStack>
  );
}
