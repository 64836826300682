import { Button, Code } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { APP } from 'lib/routes';

export default function UsernameButton({ user }) {
  return (
    <Button variant="link" as={Link} to={`${APP}/profile/${user.id}`}>
      <Code>@{user.username}</Code>
    </Button>
  );
}
