import { useInterests } from 'hooks/interests';

import { FaX } from 'react-icons/fa6';

import { Tag as ChakraTag } from '../../components/ui/tag';
import { Tooltip } from '../../components/ui/tooltip';

export default function Tag({ tag, onClose = undefined }) {
  const { text, interests } = tag;
  const { interests: tagInterests } = useInterests({
    ids: interests,
    selectableOnly: true,
    hierarchical: false
  });

  function formatInterests(interests) {
    let formatted = 'No associated interests';
    if (interests && interests.length > 0) {
      formatted =
        'Interests: ' + interests.map((i) => i.displayText).join(', ');
    }

    return formatted;
  }

  return (
    <Tooltip content={formatInterests(tagInterests)}>
      <ChakraTag
        size="lg"
        key={text}
        borderRadius="full"
        variant="surface"
        colorPalette="themeColor"
        mx="2"
        my="1"
        endElement={
          onClose ? <FaX onClick={onClose.bind(undefined, tag)} /> : ''
        }
      >
        #{text}
      </ChakraTag>
    </Tooltip>
  );
}
