import { Toaster } from './components/ui/toaster';
import { RouterProvider } from 'react-router-dom';

import { Provider } from './components/ui/provider';

import { router } from 'lib/routes';

import { nearSystem } from './Theme';

export default function App() {
  return (
    <Provider value={nearSystem}>
      <RouterProvider router={router} />
      <Toaster />
    </Provider>
  );
}
