import {
  Box,
  Button,
  Separator,
  Editable,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Stack,
  Tabs,
  Text,
  useDisclosure
} from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

import PostsList from 'components/post/PostsList';
import { usePosts } from 'hooks/posts';
import Avatar from './Avatar';
import { useUpdateUser, useUser } from 'hooks/users';

import { formatDistanceToNow } from 'date-fns';
import ChangeAvatar from './ChangeAvatar';
import { useAuth } from 'hooks/auth';
import {
  useConnectionsFromUser,
  useConnectionsToUser
} from 'hooks/connections';

import User from 'components/users/User';
import { ConnectionType } from 'lib/enums';

export default function Profile() {
  const { id } = useParams();
  const { user: authUser, isLoading: authLoading } = useAuth();
  const { user, isLoading: userLoading } = useUser(id);
  const { posts, isLoading: postsLoading } = usePosts({ uid: id });
  const { updateUser, isLoading: updateUserLoading } = useUpdateUser(id);
  const {
    isOpen: isChangeAvatarOpen,
    onOpen: onChangeAvatarOpen,
    onClose: onChangeAvatarClose
  } = useDisclosure();
  const { connections: followingUsers, isLoading: followingUsersLoading } =
    useConnectionsFromUser(id, ConnectionType.Follow);
  const { connections: followedByUsers, isLoading: followedByUsersLoading } =
    useConnectionsToUser(id, ConnectionType.Follow);

  if (userLoading) return <Spinner />;

  function handleUpdateDescription(description) {
    updateUser({
      description: description.value
    });
    user.description = description.value;
  }

  return (
    <Stack padding={5}>
      <Flex p={['4', '6']} pos="relative" align="center">
        <Stack ml="10" align="center">
          <Avatar user={user} size="xl" />
          {!authLoading && authUser.id === user.id ? (
            <Button colorPalette="themeColor" onClick={onChangeAvatarOpen}>
              Change avatar
            </Button>
          ) : (
            ''
          )}
        </Stack>

        <ChangeAvatar
          isOpen={isChangeAvatarOpen}
          onClose={onChangeAvatarClose}
        />

        <Stack spacing="10">
          <HStack spacing="10" paddingLeft="5">
            <Text fontSize="2xl">@{user.username}</Text>
            <Text fontSize={['md']}>
              Posts: {postsLoading ? '' : posts.length}
            </Text>
            <Text fontSize={['md']}>
              Joined: {formatDistanceToNow(user.createDate)} ago
            </Text>
          </HStack>

          <Editable.Root
            disabled={updateUserLoading || authLoading || id !== authUser.id}
            placeholder="Describe yourself..."
            paddingLeft="5"
            fontSize="md"
            height="100"
            onValueCommit={handleUpdateDescription}
            defaultValue={user.description}
          >
            <Editable.Preview />
            <Editable.Textarea fontSize="md" />
          </Editable.Root>
        </Stack>
      </Flex>
      <Separator />

      <Tabs.Root defaultValue="posts">
        <Tabs.List>
          <Tabs.Trigger value="posts">Posts</Tabs.Trigger>
          <Tabs.Trigger value="following">Following</Tabs.Trigger>
          <Tabs.Trigger value="followers">Followers</Tabs.Trigger>
          <Tabs.Trigger value="interests">Interests</Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="posts">
          {!posts | postsLoading ? (
            <Text>Loading...</Text>
          ) : (
            <PostsList posts={posts} />
          )}
        </Tabs.Content>
        <Tabs.Content value="following">
          <Box>
            <SimpleGrid columns={[2, 3, 4]} spacing={[2, 3]} px="10px" py="6">
              {!followingUsersLoading &&
              followingUsers &&
              followingUsers.length > 0 ? (
                followingUsers.map((following) => (
                  <User
                    key={following.toUserId}
                    uid={following.toUserId}
                    authUser={authUser}
                    isFollowed={true}
                  />
                ))
              ) : (
                <Text>Not following anyone yet</Text>
              )}
            </SimpleGrid>
          </Box>
        </Tabs.Content>
        <Tabs.Content value="followers">
          <Box>
            <SimpleGrid columns={[2, 3, 4]} spacing={[2, 3]} px="10px" py="6">
              {!followedByUsersLoading &&
              followedByUsers &&
              followedByUsers.length > 0 ? (
                followedByUsers.map((follower) => {
                  return (
                    <User
                      key={follower.fromUserId}
                      uid={follower.fromUserId}
                      authUser={authUser}
                      isFollowed={false}
                      includeActions={false}
                    />
                  );
                })
              ) : (
                <Text fontSize={['md']}>No followers yet</Text>
              )}
            </SimpleGrid>
          </Box>
        </Tabs.Content>
        <Tabs.Content value="interests">
          <HStack>
            <Box width="400px"></Box>
          </HStack>
        </Tabs.Content>
      </Tabs.Root>
    </Stack>
  );
}
