import { useAuth } from 'hooks/auth';
import { useState } from 'react';
import UserList from './UserList';
import { Box, Flex, Input, Spinner } from '@chakra-ui/react';

export default function Users(props) {
  const { user: authUser, isLoading: authLoading } = useAuth();

  // Filters
  const [filterString, setFilterString] = useState('');

  if (authLoading) return <Spinner />;

  return (
    <Box flex="1" ml="4" mx="auto" maxW="1200px">
      <Flex padding="4">
        <Input
          size="sm"
          padding="4"
          variant="flushed"
          placeholder="Search by user name or tag"
          autoComplete="off"
          onChange={(ev) => setFilterString(ev.target.value)}
          data-lpignore="true"
        />
      </Flex>

      <UserList authUser={authUser} filterString={filterString} />
    </Box>
  );
}
