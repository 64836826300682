import { Box, Flex, Input } from '@chakra-ui/react';
import { useState } from 'react';

import { useDeleteTag } from 'hooks/tags';
import NewTag from './NewTag';
import TagList from './TagList';

export default function Tags() {
  const [filter, setFilter] = useState('');

  const { deleteTag } = useDeleteTag();
  function onTagClose(tag) {
    deleteTag(tag.id);
  }

  return (
    <Box align="center" pt="50" maxWidth="800px">
      <NewTag />

      <Box flex="1" ml="4" mx="auto" maxW="800px">
        <Flex padding="4">
          <Input
            size="sm"
            padding="4"
            variant="flushed"
            placeholder="Filter tags"
            autoComplete="off"
            onChange={(ev) => setFilter(ev.target.value)}
          />
        </Flex>
      </Box>

      <TagList text={filter} onTagClose={onTagClose} />
    </Box>
  );
}
