import { Box, Spinner } from '@chakra-ui/react';
import { useTags } from 'hooks/tags';

import Tag from '../tags/Tag';

export default function TagList({ searchText, onTagClose }) {
  const { tags, isLoading } = useTags({ text: searchText });

  if (!tags || isLoading) return <Spinner />;

  return (
    <Box maxWidth="800px">
      {tags.map((tag) => (
        <Tag key={tag.id} tag={tag} onClose={onTagClose} />
      ))}
    </Box>
  );
}
