import { Button, HStack, Text } from '@chakra-ui/react';
import { useDeleteInvite } from 'hooks/invites';

export default function Invite({ invite }) {
  const { email } = invite;
  const { deleteInvite, isLoading: deleteInviteLoading } = useDeleteInvite(
    invite.id
  );

  return (
    <HStack marginY="3">
      <Text paddingLeft="5">{email}</Text>
      {!deleteInviteLoading ? (
        <Button
          marginLeft="auto"
          colorPalette="themeColor"
          onClick={deleteInvite}
          size="xs"
        >
          Remove Invite
        </Button>
      ) : (
        ''
      )}
    </HStack>
  );
}
