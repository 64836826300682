import { Circle, Float } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { APP } from 'lib/routes';

import { Avatar as ChakraAvatar } from '../../components/ui/avatar';
import { Tooltip } from '../../components/ui/tooltip';

export default function Avatar({ user, size = 'xl', overrideAvatar = null }) {
  return (
    <ChakraAvatar
      name={user.username}
      size={size}
      src={overrideAvatar || user.avatar}
      _hover={{ cursor: 'pointer', opacity: '0.8' }}
      as={Link}
      to={`${APP}/profile/${user.id}`}
    >
      {user.isDisabled ? (
        <Tooltip content="User disabled">
          <Float placement="bottom-end" offsetX="1" offsetY="1">
            <Circle bg="red" size="1.5em" outline="0.1em solid" />
          </Float>
        </Tooltip>
      ) : (
        ''
      )}
    </ChakraAvatar>
  );
}
