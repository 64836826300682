import {
  createSystem,
  defaultConfig,
  defineConfig,
  defineTextStyles
} from '@chakra-ui/react';

// Fonts
import '@fontsource/geist-sans';
import '@fontsource/outfit';

export const textStyles = defineTextStyles({
  nearBody: {
    description: 'Body text style',
    value: {
      fontFamily: 'Geist Sans',
      fontWeight: '500',
      fontSize: '16px',
      letterSpacing: '0',
      textDecoration: 'None',
      textTransform: 'None'
    }
  },

  nearNavbar: {
    description: 'Heading text style',
    value: {
      fontFamily: 'Outfit',
      fontWeight: 'bold',
      fontSize: '18px',
      letterSpacing: '0',
      textDecoration: 'None',
      textTransform: 'None'
    }
  },

  nearSlogan: {
    description: 'Slogan text style',
    value: {
      fontFamily: 'Libre Baskerville',
      fontWeight: '500',
      fontSize: '18px',
      letterSpacing: '0',
      textDecoration: 'None',
      textTransform: 'None'
    }
  }
});

const customConfig = defineConfig({
  theme: {
    semanticTokens: {
      colors: {
        nearBackground: {
          DEFAULT: {
            value: {
              _light: '#B3B7D7',
              _dark: '{colors.blue.950}'
            }
          }
        },
        navbarBackground: {
          DEFAULT: {
            value: {
              _light: '{colors.gray.200}',
              _dark: '{colors.gray.700}'
            }
          }
        },
        containerHeaderBackground: {
          DEFAULT: {
            value: {
              _light: '{colors.gray.100}',
              _dark: '{colors.gray.500}'
            }
          }
        },
        containerBackground: {
          DEFAULT: {
            value: {
              _light: '{colors.gray.200}',
              _dark: '{colors.gray.700}'
            }
          }
        },
        containerBorder: {
          DEFAULT: {
            value: {
              _light: '{colors.gray.300}',
              _dark: '{colors.gray.600}'
            }
          }
        },
        themeColor: {
          DEFAULT: {
            value: {
              _light: '{colors.blue.800}',
              _dark: '#B3B7D7'
            }
          },
          // Note, may need to define addtional properties to support different style buttons
          // https://www.chakra-ui.com/docs/components/button
          // https://www.chakra-ui.com/docs/theming/customization/colors
          solid: {
            value: {
              _light: '{colors.blue.800}',
              _dark: '#B3B7D7'
            }
          },
          fg: {
            value: {
              _light: '{colors.blue.800}',
              _dark: '#B3B7D7'
            }
          },
          outline: {
            value: {
              _light: '{colors.blue.800}',
              _dark: '#B3B7D7'
            }
          },
          surface: {
            value: {
              _light: '{colors.blue.800}',
              _dark: '#B3B7D7'
            }
          }
        }
      }
    },
    textStyles
  }
});

export const nearSystem = createSystem(defaultConfig, customConfig);
