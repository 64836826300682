import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { auth, db } from 'lib/firebase';
import { APP_DASHBOARD, LOGIN } from 'lib/routes';
import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  orderBy
} from 'firebase/firestore';
import doesUsernameOrEmailExist from 'utils/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { toaster } from '../components/ui/toaster';

export function useAuth() {
  const [authUser, isAuthLoading, error] = useAuthState(auth);
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const ref = doc(db, 'users', authUser.uid);
      const docSnap = await getDoc(ref);
      setUser(docSnap.data());
      setAuthToken(await authUser.getIdToken());
      setLoading(false);
    }

    if (!isAuthLoading) {
      if (authUser) {
        fetchData();
      } else setLoading(false);
    }
  }, [isAuthLoading, authUser]);

  return { user, authToken, isLoading, error };
}

export function useLogin() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function login({ email, password, redirectTo = APP_DASHBOARD }) {
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      toaster.create({
        title: 'Logged in',
        type: 'success',
        duration: 5000
      });
      navigate(redirectTo);
    } catch (error) {
      toaster.create({
        title: 'Login failed',
        description: error.message,
        type: 'error',
        duration: 5000
      });
      setLoading(false);
      return false; // Return false if login failed
    }

    setLoading(false);
    return true; // Return true if login succeeds
  }

  return { login, isLoading };
}

export function useRegister() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { invites, isLoading: invitesLoading } = useInvites();

  async function register({
    username,
    email,
    password,
    redirectTo = APP_DASHBOARD
  }) {
    setLoading(true);

    if (invitesLoading || !invites) {
      return;
    }

    // !!! For now, registration is by invite only
    let inviteEmails = invites?.map((invite) => {
      return invite.email;
    });

    if (!inviteEmails.includes(email)) {
      toaster.create({
        title: `Sorry. Currently registration is by invite only.`,
        type: 'error',
        duration: 5000
      });
      return;
    }

    // Ensure that username and email are unique
    const [usernameExists, emailExists] = await doesUsernameOrEmailExist(
      username,
      email
    );

    console.log(
      `Username exists: ${usernameExists} email exists: ${emailExists}`
    );
    if (usernameExists) {
      toaster.create({
        title: `Sorry. Username "${username}" is already taken. Please try again.`,
        type: 'error',
        duration: 5000
      });
    } else if (emailExists) {
      toaster.create({
        title: `Sorry. That email is already in use.`,
        type: 'error',
        duration: 5000
      });
    } else {
      try {
        const res = await createUserWithEmailAndPassword(auth, email, password);

        await setDoc(doc(db, 'users', res.user.uid), {
          id: res.user.uid,
          username: username.toLowerCase(),
          email,
          avatar: '',
          createDate: Date.now()
        });

        toaster.create({
          title: 'Account created!',
          type: 'success',
          duration: 5000
        });

        navigate(APP_DASHBOARD);
      } catch (error) {
        toaster.create({
          title: `Sorry. Signup failed. Please try again.`,
          type: 'error',
          duration: 5000
        });
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  return { register, isLoading: isLoading };
}

export function useLogout() {
  const [signOut, isLoading] = useSignOut(auth);
  const navigate = useNavigate();

  if (!signOut || isLoading) {
    return 'Signing out...';
  }

  async function logout() {
    if (await signOut()) {
      toaster.create({
        title: 'Logged out',
        type: 'success',
        duration: 5000
      });
      navigate(LOGIN);
    } else {
      toaster.create({
        title: 'Failed to log out. Yikes.',
        type: 'error',
        duration: 5000
      });
    }
  }

  return { logout, isLoading };
}

export function useInvites() {
  const [invites, isLoading, error] = useCollectionData(
    query(collection(db, 'invites'), orderBy('email', 'asc'))
  );

  if (error) {
    throw error;
  }

  return { invites, isLoading };
}
