import { Box, Button, Flex, Input } from '@chakra-ui/react';
import { useAuth } from 'hooks/auth';
import { useAddInvite } from 'hooks/invites';
import { useForm } from 'react-hook-form';

export default function NewInvite() {
  const { user: authUser, isLoading: authUserLoading } = useAuth();
  const { register, handleSubmit, reset } = useForm();
  const { addInvite, isLoading: addInviteLoading } = useAddInvite();

  function handleAddInvite(data) {
    addInvite(data.email, authUser.id);
    reset();
  }

  return (
    <Box maxW="800px" mx="auto" py="6">
      <Flex padding="4">
        <Box flex="1" ml="4">
          <form onSubmit={handleSubmit(handleAddInvite)}>
            <Box>
              <Input
                size="sm"
                variant="flushed"
                placeholder="Email address"
                autoComplete="off"
                {...register('email', { required: true })}
              />
            </Box>
            <Flex pt="2">
              <Button
                type="submit"
                colorPalette="themeColor"
                size="xs"
                ml="auto"
                isLoading={
                  addInviteLoading || authUserLoading || addInviteLoading
                }
              >
                Add Invite
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}
