import { HStack, Spinner } from '@chakra-ui/react';
import { useAuth } from 'hooks/auth';
import Avatar from './Avatar';
import { useUpdateAvatar } from 'hooks/users';

import {
  DialogRoot,
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader
} from '../../components/ui/dialog';

import { Button } from '../../components/ui/button';
import { Field } from '../../components/ui/field';

export default function ChangeAvatar({ isOpen, onClose }) {
  const { user, isLoading: authLoading } = useAuth();

  const {
    setFile,
    updateAvatar,
    isLoading: fileLoading,
    fileURL
  } = useUpdateAvatar(user?.id);

  function handleAvatarChange(event) {
    setFile(event.target.files[0]);
  }

  if (!user || authLoading || fileLoading) return <Spinner />;

  return (
    <DialogRoot isOpen={isOpen} onClose={onClose}>
      <DialogContent>
        <DialogHeader>Change Avatar</DialogHeader>
        <DialogBody>
          <HStack spacing="5">
            <Avatar user={user} overrideAvatar={fileURL} />
            <Field py="4">
              <input
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
              />
            </Field>
          </HStack>
        </DialogBody>
        <DialogFooter>
          <DialogActionTrigger asChild>
            <Button variant="outline">Cancel</Button>
          </DialogActionTrigger>

          <Button
            loadingText="Uploading"
            w="full"
            my="6"
            colorPalette="themeColor"
            onClick={updateAvatar}
            isLoading={fileLoading}
          >
            Save
          </Button>
        </DialogFooter>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
}
