import {
  Box,
  Flex,
  IconButton,
  Spinner,
  Text,
  Tooltip
} from '@chakra-ui/react';
import Avatar from 'components/profile/Avatar';
import UsernameButton from 'components/profile/UsernameButton';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from 'hooks/auth';
import { useDeleteComment } from 'hooks/comments';
import { useUser } from 'hooks/users';
import { FaTrash } from 'react-icons/fa6';

export default function Comment({ comment }) {
  const { text, id, uid, createDate: date } = comment;

  const { user, isLoading: userLoading } = useUser(uid);
  const { user: authUser, isLoading: authLoading } = useAuth();
  const { deleteComment, isLoading: deleteCommentLoading } =
    useDeleteComment(id);

  if (!user || userLoading) return <Spinner />;

  return (
    <Box px="4" py="2" maxW="600px" mx="auto" textAlign="left">
      <Flex pb="2">
        <Avatar user={user} size="md" />
        <Box flex="1" ml="4">
          <Flex borderBottom="1px solid" borderColor="themeColor" pb="2">
            <Box>
              <UsernameButton user={user} />
              <Text fontSize="xs" color="gray.500">
                {formatDistanceToNow(date)}
              </Text>
            </Box>
            {!authLoading && authUser.id === uid ? (
              <Tooltip label="Delete comment">
                <IconButton
                  size="sm"
                  ml="auto"
                  icon={<FaTrash />}
                  colorPalette="red"
                  variant="ghost"
                  isRound
                  onClick={deleteComment}
                  isLoading={deleteCommentLoading}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </Flex>
          <Box pt="2" fontSize="sm">
            <Text>{text}</Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
