import { Box, Button, Flex, Input, Spinner } from '@chakra-ui/react';
import Avatar from 'components/profile/Avatar';
import { useAuth } from 'hooks/auth';
import { useAddComment } from 'hooks/comments';
import { useForm } from 'react-hook-form';

export default function NewComment({ post }) {
  const { id: postId } = post;
  const { user, isLoading: userLoading } = useAuth();
  const { register, handleSubmit, reset } = useForm();
  const { addComment, isLoading: addCommentLoading } = useAddComment({
    postId,
    uid: user?.id
  });

  if (!user || userLoading || addCommentLoading) return <Spinner />;

  function handleAddComment(data) {
    addComment(data.text);
    reset();
  }

  return (
    <Box maxW="600px" mx="auto" py="6">
      <Flex padding="4">
        <Avatar user={user} size="sm" />
        <Box flex="1" ml="4">
          <form onSubmit={handleSubmit(handleAddComment)}>
            <Box>
              <Input
                size="sm"
                variant="flushed"
                placeholder="Write comment..."
                autoComplete="off"
                {...register('text', { required: true })}
              />
            </Box>
            <Flex pt="2">
              <Button
                type="submit"
                colorPalette="themeColor"
                size="xs"
                ml="auto"
                isLoading={addCommentLoading || userLoading}
              >
                Add Comment
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}
