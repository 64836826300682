import { Box } from '@chakra-ui/react';
import InterestTree from './InterestTree';

export default function Interests() {
  return (
    <Box align="center" pt="50" maxWidth="800px">
      <InterestTree />
    </Box>
  );
}
